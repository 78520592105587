import { FC, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useAuth } from '../hooks/use-auth';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import Login from '../containers/Login';
import PublicProfile from '../pages/public-profile/PublicProfile';
import MainLayout from '../layouts/Main/MainLayout';
import Home from 'src/pages/home/Home';
import RequireAuth from '../components/RequireAuth';
import RequireNoAuth from '../components/RequireNoAuth';
import ExtLayout from '../layouts/Extension/ExtLayout';
import OnboardingLayout from 'src/layouts/Onboarding/OnboardingLayout';
import NotFound from '../containers/NotFound';
import Privacy from '../containers/Privacy';
import 'aos/dist/aos.css';
import DetailPage from 'src/pages/detail-page/DetailPage';
import Test from '../containers/Test';
import Admin from 'src/pages/admin/Admin';
import AdminSearchIndex from '../pages/admin/search/AdminSearchIndex';
import ShopNav from '../containers/shop/ShopNav';
import Ping from '../containers/Ping';
import OnboardPage from '../pages/onboarding/onboard';
import { Toaster } from 'react-hot-toast';
import { FAQ } from 'src/containers/faq/Faq';
import Collections from 'src/pages/public-profile/components/Collections';
import Values from 'src/pages/public-profile/components/Values';
import Dashboard from 'src/pages/dashboard/Dashboard';
import AccountManagement from 'src/pages/admin/accounts/AccountManagement';
import RequireAdmin from 'src/pages/admin/RequireAdmin';
import Popup from 'src/pages/extension/popup/Popup';
import AfterInstall from 'src/pages/extension/install/AfterInstall';
import AfterUninstall from 'src/pages/extension/install/AfterUninstall';
import { ExtensionInstalledCallback } from './ExtensionInstalledCallbackRoute';
import { gtm } from 'src/lib/gtm';
import log from 'loglevel';
import config from 'src/configs/aws-config';
import ChooseRecommenders from 'src/pages/onboarding/recommenders/ChooseRecommenders';
import SavedProducts from 'src/containers/saved-products/SavedProducts';
import GlobalVectorSearch from '../pages/global-vector-search/GlobalVectorSearch';
import GlobalVectorProductsTab from '../pages/global-vector-search/components/GlobalVectorProductsTab';
import GlobalVectorExpertsTab from '../pages/global-vector-search/components/GlobalVectorExpertsTab';
import { useSegment } from 'src/contexts/segment-context';
import AdminProductReviewMatch from '../pages/admin/reviews/AdminProductReviewMatch';
import { ErrorBoundary } from '../components/ErrorBoundary';
import { ManageReviews } from 'src/pages/admin/reviews/ManageReviews';
import Blog from 'src/containers/blog/Blog';
import ReviewViewer from '../pages/public-profile/components/ReviewViewer';
import { SetupSafari } from 'src/pages/onboarding/setup-extension/SetupSafari';
import { SafariSetupFinished } from 'src/pages/onboarding/setup-extension/SafariSetupFinished';
import { SafariSetupGoogleSearch } from 'src/pages/onboarding/setup-extension/SafariSetupGoogleSearch';
import SetupExtensionLayout from 'src/layouts/Extension/SetupExtensionLayout';
import Profile from '../pages/your-profile/Profile';
import YourProducts from '../pages/your-profile/YourProducts';
import YourReviews from '../pages/your-profile/YourReviews';
import ValuesPage from 'src/pages/onboarding/values/values';
import ExpertTypesPage from 'src/pages/onboarding/expert_types/ExpertTypes';
import ExtensionRedirect from '../pages/extension/install/ExtensionRedirect';
import Update0015 from '../pages/extension/updates/Update0015';
import ExtensionSearchV2 from '../pages/extension/search/ExtensionSearchV2';
import Update0016 from '../pages/extension/updates/Update0016';
import ReviewSearch from '../pages/extension/expertise/reviews/ReviewSearch';
import ReviewSearchV2 from '../pages/extension/expertise/reviews/ReviewSearchV2';
import ManageContent from '../pages/admin/content/ManageContent';
import YourContent from '../pages/your-profile/YourContent';
import ContentEditor from '../pages/your-profile/ContentEditor';
import Expertise from '../pages/extension/expertise/Expertise';
import PopupV1 from '../pages/extension/popup/PopupV1';
import AnalyzePage from '../pages/analyze-page/AnalyzePage';
import ViewSavedAnalysis from '../pages/analyze-page/ViewSavedAnalysis';
import Ingredient from '../pages/public-profile/components/Ingredient';
import FactEditor from '../pages/your-profile/FactEditor';
import Facts from '../pages/shared/Facts';
import ProductAnalyzerLayout from '../layouts/Analysis/ProductAnalyzerLayout';
import { AnalysisFAQ } from '../containers/faq/AnalysisFaq';
import SynonymsViewer from '../pages/admin/synonyms/SynonymsViewer';

export const RouteHandler: FC = () => {
  const auth = useAuth();
  const [inIframe] = useState(() => {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  });
  const segment = useSegment();

  // Grab URL search query parameters
  const [debug, setDebug] = useState(null);

  // useSearchParams not available because there isn't yet a <Router> in the tree
  const urlSearch = window.location.search;
  useEffect(() => {
    const sp = new URLSearchParams(urlSearch);
    setDebug(sp.get('debug'));
  }, [urlSearch]);

  // Setup GTM with user_id
  useEffect(() => {
    log.debug('Init GTM for', auth.user?.user_id);
    gtm.push({ user_id: auth.user?.user_id });
    gtm.initialize(config.GTM_CONFIG);
  }, [auth.user?.user_id]);

  // Setup Segment with user info
  useEffect(() => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Note: For a guest, the user object is present, but fields are null, so we
    // need to check a field on the user object to see if it's a guest or not
    if (auth.user?.user_id) {
      // Always update the local storage for the user and account IDs, these are
      // sent to the extension and can change when the user switches accounts
      localStorage.setItem('user_id', auth.user.user_id);
      localStorage.setItem('active_account_id', auth.user.active_account_id);

      segment.identify(auth.user.user_id, {
        username: auth.user.user_name,
        display_name: auth.user.active_account?.display_name,
        active_account_id: auth.user.active_account_id,
        account_name: auth.user?.active_account?.account_name,
        email: auth.user.email,
        role: auth.user.role,
        timezone: tz,
        referrer_account_name: auth.user?.active_account?.referrer_account_name,
      });
    }
  }, [auth.user, segment]);

  const sentryCreateBrowserRouter =
    Sentry.wrapCreateBrowserRouter(createBrowserRouter);
  // TODO: consider rewriting this to use json instead of jsx
  const router = sentryCreateBrowserRouter(
    createRoutesFromElements(
      <>
        {/* The shop path can be accessed by authenticated users and guests 
                          Layout should change if it's in a iFrame */}
        <Route
          element={inIframe || debug === 'ext' ? <ExtLayout /> : <MainLayout />}
          errorElement={<ErrorBoundary />}
        >
          <Route
            path="shop"
            element={<ShopNav inIframe={inIframe || debug === 'ext'} />}
          />
          <Route path="ext/search" element={<ExtensionSearchV2 />} />
          <Route path="review-search" element={<ReviewSearch />} />
          <Route path="ext/review-search" element={<ReviewSearchV2 />} />
          <Route path="ext/v1/expertise" element={<Expertise />} />
        </Route>

        <Route element={<OnboardingLayout />} errorElement={<ErrorBoundary />}>
          <Route path="extension-installed" element={<AfterInstall />} />
          <Route element={<RequireAuth />}>
            <Route path="onboarding">
              <Route path="experts" element={<ChooseRecommenders />} />
              <Route path="values" element={<ValuesPage />} />
              <Route path="expert_types" element={<ExpertTypesPage />} />
            </Route>
            <Route
              path="safari-setup-google-search"
              element={<SafariSetupGoogleSearch />}
            />
          </Route>
          <Route
            path="signup"
            element={<ExtensionInstalledCallback component={<OnboardPage />} />}
          />
        </Route>

        <Route
          element={<SetupExtensionLayout />}
          errorElement={<ErrorBoundary />}
        >
          <Route element={<RequireAuth to={'/signup'} />}>
            <Route path="safari-setup" element={<SetupSafari />}></Route>
            <Route
              path="safari-setup-finished"
              element={<SafariSetupFinished />}
            />
          </Route>
        </Route>

        <Route
          element={<ProductAnalyzerLayout />}
          errorElement={<ErrorBoundary />}
        >
          <Route path="analyze-page" element={<AnalyzePage />} />
          <Route
            path="analyze-page/:analysis_id"
            element={<ViewSavedAnalysis />}
          />
          <Route path="analysis-faq" element={<AnalysisFAQ />} />
        </Route>

        <Route element={<MainLayout />} errorElement={<ErrorBoundary />}>
          <Route path="redirect-to-extension" element={<ExtensionRedirect />} />
          <Route
            index
            element={
              auth.isAuthenticated ? (
                <ExtensionInstalledCallback component={<Dashboard />} />
              ) : (
                <Home />
              )
            }
          />
          <Route path="about" element={<Home />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="faq" element={<FAQ />} />

          {/* Later add a list of blogs on this page */}
          {/* <Route path="blog" element={<Blog />}> */}
          <Route path="blog/:blog_id" element={<Blog />} />
          <Route path="blog/:track/:blog_id" element={<Blog />} />
          {/* </Route> */}

          {/* The shop subpages are for specific products */}
          <Route path="shop/:product_id" element={<DetailPage />} />
          {/* The track is to help with some analytics where URL params aren't reliable */}
          <Route path="/shop/v1/:track/:product_id" element={<DetailPage />} />
          <Route
            path="shop/:product_id/:primary_account_id"
            element={<DetailPage />}
          />
          <Route path="test" element={<Test />} />

          {/* The 'reviews' path is for public reviews, also attached to an account */}
          <Route path="reviews/:review_id" element={<ReviewViewer />} />

          {/* the 'p' path is a public recommenders profile */}
          <Route path="p">
            <Route path=":account_name" element={<PublicProfile />} />
            <Route
              path=":account_name/reviews/:review_id"
              element={<ReviewViewer />}
            />
            <Route
              path=":account_name/about"
              element={<PublicProfile iniTab="about" />}
            />
            <Route path=":account_name/collections" element={<Collections />} />
            <Route path=":account_name/values" element={<Values />} />
            <Route
              path=":account_name/ingredients/:ingredient_id"
              element={<Ingredient />}
            />
            <Route path=":account_name/facts" element={<Facts />} />
          </Route>

          {/* the 'r' path is for recommenders to share with their audience */}
          <Route path="r">
            <Route path=":account_name" element={<PublicProfile ext />} />
          </Route>

          <Route path="extension-uninstalled" element={<AfterUninstall />} />

          <Route path="search" element={<GlobalVectorSearch />}>
            <Route path="" element={<GlobalVectorProductsTab />} />
            <Route path="experts" element={<GlobalVectorExpertsTab />} />
          </Route>

          <Route path="ext-update-0.0.15" element={<Update0015 />} />
          <Route path="ext-update-0.0.16" element={<Update0016 />} />

          <Route element={<RequireAuth />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="profile" element={<Profile />} />
            <Route path="profile/products" element={<YourProducts />} />
            <Route path="profile/reviews" element={<YourReviews />} />
            <Route path="profile/content" element={<YourContent />} />
            <Route
              path="profile/content/:content_id"
              element={<ContentEditor />}
            />
            <Route path="profile/facts/" element={<Facts />} />
            <Route path="profile/facts/:fact_id" element={<FactEditor />} />
            <Route path="saved-products" element={<SavedProducts />} />

            {/* ADMIN routes  */}
            <Route path="admin" element={<RequireAdmin />}>
              <Route index element={<Admin />} />
              <Route path="search-index" element={<AdminSearchIndex />} />
              <Route path="accounts" element={<AccountManagement />} />
              <Route path="matching" element={<AdminProductReviewMatch />} />
              <Route path="manage-reviews" element={<ManageReviews />} />
              <Route path="manage-content" element={<ManageContent />} />
              <Route path="manage-synonyms" element={<SynonymsViewer />} />
            </Route>
          </Route>

          {/* When logged in, these pages redirect to home */}

          <Route element={<RequireNoAuth children="" />}>
            <Route path="login" element={<Login />} />
          </Route>

          <Route path="*" element={<NotFound />} />
        </Route>
        {/* Content in 'ext' is meant for injection in the browser extension
            and does not have any of the regular navigation layout. Each
            page will determine what it does with an unauthenticated user. */}
        <Route element={<ExtLayout />}>
          <Route path="ext/recommend" element={<Popup />} />
          <Route path="ext/v1/popup" element={<PopupV1 />} />
          <Route path="ext/ping" element={<Ping />} />
        </Route>
      </>,
    ),
  );

  return (
    <>
      <Toaster position="top-center" />
      <RouterProvider fallbackElement={<ErrorBoundary />} router={router} />
    </>
  );
};
