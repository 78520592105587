import log from 'loglevel';
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { PaperAnalyze } from '../shared/PaperAnalyze';
import { useSegment } from '../../../contexts/segment-context';
import EwgIcon from '../../../assets/icons/EwgIcon';
import { useGetPublicProfile } from '../../../hooks/account-hooks';
import { Link } from 'react-router-dom';
import SentimentIcon from '../../../assets/icons/SentimentIcon';
import {
  AnalyzeProductResult,
  ExpertIngredientAnalysis,
  ExpertProductAnalysis,
} from 'src/types/saved_product_analyses';
import EvaluateWidget from '../shared/EvaluateWidget';
import { useAuth } from '../../../hooks/use-auth';
import IngredientAudio from './AudioWidget';
import IngredientMosaic from './IngredientMosaic';

export type IngredientsArray = {
  ingredient: string;
  experts: {
    [expertAccountName: string]: ExpertIngredientAnalysis;
  };
  index: number;
}[];

type ProductSummaryWidgetProps = {
  analysis: AnalyzeProductResult;
};
export function ProductSummaryWidget({ analysis }: ProductSummaryWidgetProps) {
  const analysis_id = analysis.analysis_id;

  const segment = useSegment();
  const [shownSummaries, setShownSummaries] = useState<{
    [expertName: string]: ExpertProductAnalysis;
  }>({});
  const [hiddenSummaries, setHiddenSummaries] = useState<{
    [expertName: string]: ExpertProductAnalysis;
  }>({});

  const [ingredients, setIngredients] = useState<IngredientsArray>(null);
  const [shownIngredients, setShownIngredients] =
    useState<IngredientsArray>(ingredients);
  const analysisRef = useRef<HTMLDivElement>(null);

  // Update and format the summaries when the analysis is complete
  useEffect(() => {
    if (!analysis) return;

    // Convert ingredients object from server into an array
    const ing = Object.keys(analysis.ingredients).map((ingredient, i) => ({
      ingredient: ingredient,
      experts: analysis.ingredients[ingredient],
      index: i,
    }));
    setIngredients(ing);
    setShownIngredients(ing);

    function getConclusion(text: string) {
      const conclusionMarker = '**Conclusion**';
      const conclusionIndex = text.indexOf(conclusionMarker);
      if (conclusionIndex !== -1)
        return text.substring(conclusionIndex + conclusionMarker.length).trim();
      return 'not found';
    }

    function getShownAndHidden() {
      const shown = {};
      const hidden = {};
      Object.keys(analysis.product_summaries).forEach((account_name) => {
        const accountSummary = { ...analysis.product_summaries[account_name] };
        const conclusion = getConclusion(accountSummary.product_summary);
        if (conclusion.toLowerCase().startsWith('not found')) {
          hidden[account_name] = accountSummary;
        } else {
          accountSummary.product_summary = conclusion;
          shown[account_name] = accountSummary;
        }
      });
      return { shown, hidden };
    }

    const { shown, hidden } = getShownAndHidden();
    setShownSummaries(shown);
    setHiddenSummaries(hidden);
  }, [analysis]);

  //console.log(ingredients);

  const handleCopyClick = async () => {
    try {
      // See https://developer.mozilla.org/en-US/docs/Web/API/Clipboard/write
      const html = analysisRef.current.innerHTML;
      const text = analysisRef.current.innerText;
      const htmlBlob = new Blob([html], { type: 'text/html' });
      const textBlob = new Blob([text], { type: 'text/plain' });
      const data = [
        new ClipboardItem({ 'text/html': htmlBlob, 'text/plain': textBlob }),
      ];
      await navigator.clipboard.write(data);
      segment.track('Copy Analysis Clicked', {
        brand: analysis.brand,
        title: analysis.title,
      });
    } catch (e) {
      // Some older browsers don't support clipboard.write
      prompt('Copy error:', e.message);
      log.warn('Could not copy text', e);
    }
  };

  return (
    <PaperAnalyze>
      {analysis && (
        <Box sx={{ position: 'relative' }} ref={analysisRef}>
          <Stack direction="row" justifyContent={'space-between'}>
            <Typography sx={{ fontWeight: 600, fontSize: 26 }}>
              Expert Summaries
            </Typography>
            <IconButton color="primary" onClick={() => handleCopyClick()}>
              <ContentCopyIcon />
            </IconButton>
          </Stack>
          <Stack direction="column" spacing={1} sx={{ mt: 3 }}>
            {Object.entries(shownSummaries).map(([account_name, analysis]) => (
              <OverallSummary
                key={account_name}
                product_analysis_id={analysis_id}
                analysis={analysis}
                account_name={account_name}
              />
            ))}
          </Stack>
          {Object.keys(hiddenSummaries).length > 0 && (
            <Box>
              <Divider sx={{ my: 2 }} />
              <Typography color="primary.main" fontWeight={600} sx={{ mb: 2 }}>
                No relevant content was found for{' '}
                {formatList(Object.keys(hiddenSummaries))}
              </Typography>
            </Box>
          )}
          {ingredients && (
            <>
              <Typography sx={{ fontWeight: 500, fontSize: 24, my: 4 }}>
                Ingredients
              </Typography>
              <Box sx={{ width: 1, display: 'flex', justifyContent: 'center' }}>
                <IngredientMosaic
                  ingredients={ingredients}
                  shownIngredients={shownIngredients}
                  setShownIngredients={setShownIngredients}
                />
              </Box>
              <Stack direction="column" spacing={2} sx={{ minHeight: 1200 }}>
                {shownIngredients.length === 0 && (
                  <Box
                    sx={{
                      width: 1,
                      display: 'flex',
                      justifyContent: 'center',
                      pt: 5,
                    }}
                  >
                    <Typography>(No ingredients match the filters)</Typography>
                  </Box>
                )}
                {shownIngredients.map((ing, i) => (
                  <Box key={ing.ingredient}>
                    <SingleIngredientSummary
                      name={ing.ingredient}
                      product_analysis_id={analysis_id}
                      experts={ing.experts}
                    />
                    {i < shownIngredients.length - 1 && (
                      <Divider aria-hidden="true" sx={{ mt: 2 }} />
                    )}
                  </Box>
                ))}
              </Stack>
            </>
          )}
        </Box>
      )}
    </PaperAnalyze>
  );
}

function formatList(items: string[]) {
  const itemCount = items.length;

  if (itemCount === 0) {
    return '';
  } else if (itemCount === 1) {
    return items[0];
  } else if (itemCount === 2) {
    return items.join(' and ');
  } else {
    const lastItem = items.pop();
    return `${items.join(', ')}, and ${lastItem}`;
  }
}

type OverallSummaryProps = {
  product_analysis_id: string;
  analysis: ExpertProductAnalysis;
  account_name: string;
};
const OverallSummary = ({
  product_analysis_id,
  analysis,
  account_name,
}: OverallSummaryProps) => {
  const [hover, setHover] = useState(false);
  const accountData = useGetPublicProfile(account_name, true);

  const handleExpertClick = (accountName) => {
    log.trace('Navigating to /p/', accountName);
  };

  if (accountData.isLoading) return null; //<div>loading...</div>;
  if (accountData.isError) return null; //<div>error</div>;
  const expert = accountData.data;

  return (
    <Stack
      direction="column"
      sx={{ pb: 1, position: 'relative' }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Stack direction="row" spacing={2} sx={{ mb: { xs: 1, sm: 1 } }}>
        {false && (
          <Avatar
            component={Link}
            sx={{
              width: 50,
              height: 50,
              fontSize: 25,
              alignItems: 'center',
              cursor: 'pointer',
            }}
            to={`/p/${expert.account_name}`}
            alt={expert.display_name}
            src={expert.avatar_url}
            onClick={() => handleExpertClick(expert.account_name)}
          >
            {expert.avatar_url ? null : expert.display_name.slice(0, 2)}
          </Avatar>
        )}
        <Typography
          component={Link}
          sx={{
            fontWeight: 600,
            fontSize: 17,
            textDecoration: 'none',
            color: 'inherit',
            mr: { xs: 0, sm: 2 },
            mb: { xs: 0.5, sm: 0 },
            cursor: 'pointer',
          }}
          to={`/p/${expert.account_name}`}
          onClick={() => handleExpertClick(expert.account_name)}
        >
          {expert.display_name}
        </Typography>
      </Stack>
      <MarkdownBox>{analysis.product_summary}</MarkdownBox>
      <EvaluateWidget
        account_name={account_name}
        product_analysis_id={product_analysis_id}
        analysis={analysis}
        show={hover}
      />
    </Stack>
  );
};

type SingleIngredientSummaryProps = {
  product_analysis_id: string;
  name: string;
  experts: { [key: string]: ExpertIngredientAnalysis };
};
const SingleIngredientSummary = ({
  product_analysis_id,
  name,
  experts,
}: SingleIngredientSummaryProps) => {
  const expertNames = Object.keys(experts);

  return (
    <Box>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          borderRadius: '10px 10px 0 0',
          px: 2,
          py: 1,
          bgcolor: 'primary.light2',
        }}
      >
        <Typography sx={{ fontWeight: 600, fontSize: 18 }}>{name}</Typography>
        <IngredientAudio ingredientName={name} />
      </Stack>
      <Stack
        direction="column"
        sx={{
          pl: { xs: 0.2, sm: 2 },
          py: 1,
          bgcolor: '#BEB4F910',
          borderRadius: '0 0 10px 10px',
        }}
        spacing={1}
      >
        {expertNames.map((expert) => (
          <GenericExpertIngredientSummary
            key={expert}
            expert={expert}
            product_analysis_id={product_analysis_id}
            analysis={experts[expert]}
            expertCount={expertNames.length}
          />
        ))}
      </Stack>
    </Box>
  );
};

const ICON_SIZE = 40;

type ExpertIngredientSummaryProps = {
  expert: string;
  product_analysis_id: string;
  analysis: ExpertIngredientAnalysis;
  expertCount: number;
};
const GenericExpertIngredientSummary = ({
  expert,
  product_analysis_id,
  analysis,
  expertCount,
}: ExpertIngredientSummaryProps) => {
  const [hover, setHover] = useState(false);
  const { user } = useAuth();

  let showSentimentIcon = analysis?.score && analysis.score !== 'unknown';
  if (expert === 'ireadlabelsforyou' && user?.role !== 'admin')
    showSentimentIcon = false;

  const hideLeftMargin = expertCount === 1 && !showSentimentIcon;

  let expertIngredientIcon = (
    <SentimentIcon
      sx={{ width: ICON_SIZE * 0.75, height: ICON_SIZE * 0.75 }}
      score={analysis.score}
    />
  );
  if (expert === 'ewg') {
    expertIngredientIcon = (
      <EwgIcon
        sx={{ width: ICON_SIZE, height: ICON_SIZE }}
        min={analysis.score[0]}
        max={analysis.score[1]}
      />
    );
  }

  const formatSummary = (summary: string) => {
    // If the summary starts with [[unknown]], remove it
    if (summary.startsWith('[[unknown]]')) {
      return summary.slice(11);
    }
    return summary;
  };

  return (
    <Stack
      direction="row"
      alignItems={'center'}
      spacing={1}
      sx={{ position: 'relative' }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {!hideLeftMargin && (
        <Box
          sx={{
            width: ICON_SIZE * 1.5,
            minWidth: ICON_SIZE * 1.5,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {showSentimentIcon && expertIngredientIcon}
        </Box>
      )}
      <MarkdownBox>{`**${expert}**: ${formatSummary(
        analysis.summary,
      )}`}</MarkdownBox>
      <EvaluateWidget
        product_analysis_id={product_analysis_id}
        account_name={expert}
        analysis={analysis}
        show={hover}
      />
    </Stack>
  );
};

const MarkdownBox = ({ children }) => {
  return (
    <Box
      sx={{
        '& div.markdown': { fontSize: { xs: 15, sm: 16 } },
        '& p:first-of-type': { marginTop: 0 },
        '& p:last-child': { marginBottom: 0 },
      }}
    >
      <ReactMarkdown className="markdown" linkTarget="_blank">
        {children}
      </ReactMarkdown>
    </Box>
  );
};
